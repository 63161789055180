<template>
    <div class="edu-frame">
        <edu-nav-bar title="活动列表" :show="show" @showPopup="show=true" @hidePopup="show=false"></edu-nav-bar>
        <div class="content-frame">
			<div  v-for="(a, index) in activtys" :key="index" class="edu-group class-item">
			<div class="class-detail">
				<div style="border-left:solid #ff0000 4px;padding-left: 5px;" class="class-detail-title">{{a.activityName}}</div>
				<div style="margin-top: 10px;" class="class-detail-row-title">
					<span class="row_title">推荐星级</span>
					<van-rate v-model="a.star" :size="15" void-icon="star" void-color="#eee" readonly/>
				</div>
				<div class="class-detail-row-title">
					<span class="row_title">承办方</span>
					<span style="color: red;">{{a.organisers}}</span>
				</div>
				<div class="class-detail-row-title">
					<span class="row_title">活动时间</span>
					<span style="color: red;">{{a.beginTime}} - {{a.endTime}}</span>
				</div>
				<div class="class-detail-row-title" style="text-align: right;">
					<van-button style="width: 5em;" @click="todetail(a)" type="danger" size="mini">{{a.isNeedPay?'支付':'登记'}}</van-button>
				</div>
			</div>
        </div>
		</div>
    </div>
</template>
<script>
    import {Button, Rate, Dialog} from "vant";
    import EduNavBar from "@/components/EduNavBar";
    import Tools from "@/api/Tools";
    import XEUtils from 'xe-utils';
	import ActivityApi from "@/api/ActivityApi";

    export default {
        components: {
            EduNavBar,
            VanButton: Button,
            VanRate: Rate,
            [Dialog.Component.name]: Dialog.Component,
        },
        filters: {
            filterDate(date) {
                return XEUtils.toDateString(date, 'yyyy-MM-dd HH:mm:ss')
            }
        },
        data() {
            return {
				show: false,
				submitting: false,
				cust: Tools.getCurCust(),
				activtys:[],
				addParams:{},
            }
        },
        methods: {
			list(){
				this.loading = true;
				var pageCondition = {page: 1, pageSize: 1000, total: 0, condition: {}}
				ActivityApi.list(pageCondition).then(response => {
          this.loading = false
          this.activtys = response.res.list;
				}).catch(()=>{
          this.loading = false
				})
			},
			todetail(a){
				this.$router.push({name: 'hdgljj', query: {activitySettingMasterID:a.activitySettingMasterID,activityName:a.activityName,intro:a.intro.replace('<p>', '').replace('</p>', ''),isNeedPay:a.isNeedPay}})
			},
        },
        mounted() {
			this.list();
        }
    }
</script>
<style scoped>
	.row_title{
		display: inline-block;
		padding-right: 2em;
		min-width: 5em;
		color: gray;
	}
</style>
